import React from 'react'
import styles from '@styles/components/LandingPageHero.module.scss'
import LandingPageActions from './LandingPageActions'

const LandingPageHero = () => {
  return (
    <div className={styles['container']}>
      <div className={styles['content-container']}>
        <div className={styles['content-alignment-container']}>
          <div className={styles['content']}>
            <div className={styles['content-text']}>
              <span>Take your designs to new dimensions</span>
              <div>
                <p>
                  {`Introducing a new way to create stunning 3D artwork for brand graphics,
                  illustrations, and more. With just one design, you can unlock endless
                  variations, effortlessly.`}
                </p>
                {/* <p
                  style={{ marginTop: 4 }}
                  className={styles['community-link']}
                  onClick={() => router.push('/discover')}>
                  View what people are making
                </p> */}
              </div>
            </div>
            <LandingPageActions />
          </div>
        </div>
        <div className={styles['video-container']}>
          <video className={styles['video']} autoPlay loop muted playsInline>
            <source src="landing_page/header_video.mp4" type="video/mp4" />
          </video>
          <div className={styles['video-container-overlay']}></div>
        </div>
      </div>
    </div>
  )
}

export default LandingPageHero
