import React, { useState } from 'react'
import cn from 'classnames'
import { ActionButton } from 'ui'
import styles from '@styles/components/LandingPageVideos.module.scss'

const videos = [
  {
    label: 'Create',
    desc: `Combine and blend shapes together and create infinite 
    variations from multiple perspectives.`
  },
  {
    label: 'Refine',
    desc: `Play with different style effects to create the look you want.`
  },
  {
    label: 'Collaborate',
    desc: `Share a link to your Neo artwork with your team and view it together..`
  },
  {
    label: 'Deliver',
    desc: `Export your artwork to multiple file formats.
     To continue refining your Neo file, send it directly to Adobe Illustrator
      as editable vectors to customize to your unique design style.`
  }
]

const LandingPageVideos = () => {
  const [activeSection, setActiveSection] = useState(0)
  const currentVideoIndex = activeSection + 1

  return (
    <div className={styles['container']}>
      <div className={styles['video-container']}>
        <video
          className={styles['video']}
          key={`video-${currentVideoIndex}`}
          autoPlay
          loop
          muted
          playsInline>
          <source
            src={`landing_page/video_picker/${currentVideoIndex}.mp4`}
            type="video/mp4"
          />
        </video>
      </div>
      <div className={styles['video-picker']}>
        {videos.map(({ label }, i) => (
          <ActionButton
            variant="black"
            size="l"
            className={cn(styles['video-switch-button'], {
              [styles['active']]: activeSection === i
            })}
            selected={false}
            active={activeSection === i}
            onClick={() => setActiveSection(i)}
            key={i}>
            <span>{label}</span>
          </ActionButton>
        ))}
      </div>
      <span className={styles['video-blurb']}>
        {videos[activeSection].desc}
      </span>
    </div>
  )
}

export default LandingPageVideos
