import React from 'react'
import styles from '@styles/components/LandingPageFeatureCards.module.scss'

const cards = [
  {
    title: `
      Design volumetric shapes with ease
    `,
    desc: `
      Built with familiar controls so you can get started quickly.
      Combine and blend shapes together from multiple perspectives.
    `
  },
  {
    title: `
      Rapidly visualize your concepts 
    `,
    desc: `
      Fill your artwork with imagery that matches your unique composition
      to use for creative ideation, quick comps, and more. 
    `
  },
  {
    title: `
      Send Neo artwork to Adobe Illustrator
    `,
    desc: `
      Export your artwork into Adobe Illustrator as editable vectors
      to refine and customize to your unique design style.
    `
  },
  {
    title: `
      Quickly apply and adjust style effects 
    `,
    desc: `
      Experiment with different style effects (Realistic, Illustrator-ive, Pixel, Illustrative)
      to transform your design in just a few clicks. 
    `
  },
  {
    title: `
      Ideate and edit endless variations
    `,
    desc: `
      Edit, alter, or rotate shapes, lighting and perspective to achieve
      the look you want in endless angles and variations, non-destructive.
    `
  },
  {
    title: `
      View files together with your team
    `,
    desc: `
      Share a link to your Neo artwork and review it together from a web browser.
    `
  }
]

const LandingPageFeatureCards = () => {
  return (
    <>
      {cards.map(({ title, desc }, i) => (
        <div className={styles['card']} key={title + i}>
          <video
            className={styles['card-img']}
            autoPlay
            muted
            loop
            playsInline
            preload="none">
            <source
              src={`landing_page/feature_cards/${i + 1}.mp4`}
              type="video/mp4"
            />
            <meta itemProp="name" content="Onboarding features video" />
            <meta itemProp="description" content="Project Neo features" />
          </video>
          <div className={styles['card-content']}>
            <span className={styles['card-title']}>{title}</span>
            <span className={styles['card-description']}>{desc}</span>
          </div>
        </div>
      ))}
    </>
  )
}

export default LandingPageFeatureCards
