import { FC, useCallback, useEffect, useState } from 'react'
import cn from 'classnames'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import styles from '@styles/components/BarebonesProjectCard.module.scss'
import ImgPlaceHolderIcon from '/public/img_placeholder.svg'

export interface BarebonesProjectCardProps {
  imgSrc: string
  cardWidth: number
  cardHeight: number
  tag: string
  href: string
  onClick?: () => any
  isMobileLayout: boolean
}

const BarebonesProjectCard: FC<BarebonesProjectCardProps> = ({
  imgSrc,
  cardWidth,
  cardHeight,
  tag,
  href,
  onClick,
  isMobileLayout
}) => {
  const [imageLoaded, setImageLoaded] = useState(false)
  const [errorLoadingImage, setErrorLoadingImage] = useState(false)
  const onImageLoad = useCallback(() => setImageLoaded(true), [])
  const onImageLoadErr = useCallback(() => setErrorLoadingImage(true), [])

  const mobileCardWidth = Math.floor(cardWidth * 0.85)
  const mobileCardHeight = Math.floor(cardHeight * 0.85)

  const style = {
    width: isMobileLayout ? mobileCardWidth : cardWidth,
    height: isMobileLayout ? mobileCardHeight : cardHeight
  }

  return (
    <div style={style}>
      <a
        onClick={onClick}
        data-testid="project-card-container"
        className={styles['container']}>
        <div style={style} className={styles['project-card']}>
          <div style={style} className={styles['project-card__img-container']}>
            <div
              style={{ opacity: 1, padding: 0, borderRadius: 12 }}
              className={cn(styles['project-card__content'])}>
              {(!imageLoaded || errorLoadingImage) && (
                <div className={styles['img-placeholder']}>
                  <div className={styles['img-placeholder-content']}>
                    {errorLoadingImage ? (
                      <ImgPlaceHolderIcon />
                    ) : (
                      <div
                        className={
                          styles['img-placeholder-skeleton-container']
                        }>
                        <Skeleton
                          height={cardHeight}
                          width="100%"
                          baseColor="#e6e6e6"
                          highlightColor="#f2f2f2bf"
                        />
                      </div>
                    )}
                  </div>
                </div>
              )}
              <img
                style={style}
                className={styles['project-card__img']}
                onLoad={onImageLoad}
                onError={onImageLoadErr}
                src={imgSrc}
                alt={tag}
              />
            </div>
          </div>
        </div>
      </a>
    </div>
  )
}

export default BarebonesProjectCard
