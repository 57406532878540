import React from 'react'
import Head from 'next/head'
import { useRouter } from 'next/router'
import SpectrumV2Provider from '@react/react-spectrum/Provider'
import { Button, Theme } from 'ui'
import { useAuth } from '@hooks/useAuth'
import { useIsMobileLayout } from '@hooks/useMobileLayout'
import { AppNameBeta } from '@constants/appNameBeta'
import LandingPageHero from './LandingPageHero'
import LandingPageCategoryCards from './LandingPageCategoryCards'
import LandingPageVideos from './LandingPageVideos'
import LandingPageFeatureCards from './LandingPageFeatureCards'
import CarouselWrapper from '@components/CarouselWrapper/CarouselWrapper'
import Footer from '@components/footer/Footer'
import CommunityAppBar from '@components/navigation/CommunityAppBar'
import styles from '@styles/components/LandingPage.module.scss'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { SusiLightDialog } from '@services/auth/SusiLight/SusiLightDialog'

const LandingPage = () => {
  const router = useRouter()
  const { status } = useAuth()
  const isMobileLayout = useIsMobileLayout()
  const flags = useFlags()

  return (
    <>
      <Head>
        <title>{`${AppNameBeta} from Adobe 3D & Immersive Labs`}</title>
        <meta
          name="description"
          content="Make illustrations faster with Project Neo"
        />
        <link rel="icon" type="image/svg+xml" href="/Neo.svg" />
      </Head>
      <Theme theme="spectrum" color="light">
        <SpectrumV2Provider theme="light" style={{ background: 'inherit' }}>
          <div className={styles['container']}>
            <header className={styles['header']}>
              <CommunityAppBar isAuthenticated={status === 'AUTHENTICATED'} />
            </header>
            {flags['base-pf-ui-login-modal'] && <SusiLightDialog />}
            <main className={styles['main']}>
              <LandingPageHero />
              <CarouselWrapper
                autoScroll
                key="project-showcase-carousel"
                className={styles['project-showcase-cards']}
                cards={
                  <LandingPageCategoryCards isMobileLayout={isMobileLayout} />
                }
              />
              <LandingPageVideos />
              <div className={styles['feature-showcase-container']}>
                <CarouselWrapper
                  key="feature-showcase-carousel"
                  cards={<LandingPageFeatureCards />}
                  className={styles['feature-showcase-cards']}
                  gap={50}
                />
              </div>
              <div className={styles['content-container']}>
                <div className={styles['content-text']}>
                  <span>Discover what you can do with Project Neo</span>
                </div>
              </div>

              <div className={styles['community-teaser']}>
                <Button
                  onClick={() => router.push('/discover')}
                  title="/discover"
                  size="l"
                  className={styles['community-project-button']}
                  variant="primary">
                  Browse the community
                </Button>
                <img src={'landing_page/community_mosaic.png'} />
              </div>
            </main>
            <footer>
              <Footer />
            </footer>
          </div>
        </SpectrumV2Provider>
      </Theme>
    </>
  )
}

export default LandingPage
